<template lang="pug">
modal(size='xl' @close='$emit("close")')
  btn(class='!absolute right-3 top-5 z-[10000] !hidden md:!flex' plain icon='x' data-target='form-header-close' @click='$emit("close")')
  pcon-product-configurator(
    ref='configurator'
    :productId='productId'
    :pconInfo='pconInformation'
    :disabled='disabled'
    :catalogItem='catalogItem'
    stretchConfigColumn
    class='w-full flex-1 rounded-xl [&_#viewer]:bg-grey-50'
    style='--pcon-config-max-height: 80vh'
  )
    template(#options)
      btn(class='md:!hidden' plain icon='x' data-target='form-header-close' @click='$emit("close")')
    template(#title='{ article }')
      template(v-if='article')
        p(class='text-sm text-text-light') {{ article.manufacturerName }}
        h5(class='mb-0 pb-0 pt-0') {{ name || (catalogItem && catalogItem.label) || article.seriesName || article.finalArticleNumber }}
        p(v-if='!name && !catalogItem' class='pt-0') {{ article.shortText }}
    template(#default='{ loading }')
      btn(v-if='!loading' medium :disabled='disabled' block :loading='loadingSubmit' class='mt-4' @click='submit') {{ addFromCatalog ? $t('Add') : $t('Save') }}
</template>
<script setup lang="ts">
import { useProjectsStore } from '../store'
import PconProductConfigurator from '@/PconProductConfigurator.vue'
import { useMessages } from '@/components/Message'
import { useGlobalPconItemStore } from '@/composables/pcon'
import { useInquiriesStore } from '@/pages/inquiries/store'
import { useTranslation } from '@/plugins/translation'
import { type IPconInformation } from '@/types'
import { onMounted } from 'vue'
import { ref } from 'vue'

const props = defineProps<{
  pconInformation?: IPconInformation
  disabled?: boolean
  projectId: string
  inquiryId?: string
  productId?: string
  parentProductId?: string
  name?: string
  addFromCatalog?: boolean
}>()

const emit = defineEmits(['close'])
const configurator = ref<InstanceType<typeof PconProductConfigurator> | null>(null)
const loadingSubmit = ref(false)
const store = useProjectsStore()
const inqStore = useInquiriesStore()
const { $msg } = useMessages()
const { $t } = useTranslation()
const { catalogItem } = useGlobalPconItemStore()

onMounted(() => {
  // close if pcon Infromation was not provided by prop or global store
  if (!props.pconInformation && !catalogItem.value) {
    emit('close')
  }
})
const submit = async () => {
  loadingSubmit.value = true
  const pconDTO = await configurator.value!.getPconDataForCurrentBasketItem(catalogItem.value?.label)
  try {
    const value = props.inquiryId
      ? await inqStore.addOrEditPconProduct(props.projectId, props.inquiryId, '', props.productId, pconDTO, props.name)
      : await store.addOrEditPconProduct(
          props.projectId,
          props.inquiryId,
          '',
          props.productId,
          undefined,
          pconDTO,
          props.name,
          props.parentProductId,
        )
    if (!value) {
      $msg.error($t('Produkt kann nicht hinzugefügt werden.'))
    } else {
      emit('close')
    }
  } catch (e) {
    $msg.error($t('Produkt kann nicht hinzugefügt werden.'))
  }
  loadingSubmit.value = false
}
</script>
